/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./known-for-widget.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./known-for-widget.component";
import * as i5 from "../../titles/title-urls.service";
var styles_KnownForWidgetComponent = [i0.styles];
var RenderType_KnownForWidgetComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_KnownForWidgetComponent, data: {} });
export { RenderType_KnownForWidgetComponent as RenderType_KnownForWidgetComponent };
function View_KnownForWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "separator"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["|"]))], null, null); }
function View_KnownForWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "credit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(3, null, ["", " (", ")"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.urls.mediaItem(_v.context.$implicit); _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.name; var currVal_4 = _v.context.$implicit.year; _ck(_v, 3, 0, currVal_3, currVal_4); }); }
export function View_KnownForWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KnownForWidgetComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_KnownForWidgetComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.person.known_for && _co.person.popular_credits.length); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.person.popular_credits, 0, _co.showCredits)); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.person.known_for; _ck(_v, 1, 0, currVal_0); }); }
export function View_KnownForWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "known-for-widget", [], null, null, null, View_KnownForWidgetComponent_0, RenderType_KnownForWidgetComponent)), i1.ɵdid(1, 114688, null, 0, i4.KnownForWidgetComponent, [i5.TitleUrlsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KnownForWidgetComponentNgFactory = i1.ɵccf("known-for-widget", i4.KnownForWidgetComponent, View_KnownForWidgetComponent_Host_0, { person: "person", showCredits: "showCredits" }, {}, []);
export { KnownForWidgetComponentNgFactory as KnownForWidgetComponentNgFactory };
